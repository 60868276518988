import $ from 'jquery';
$(document).ready(() => {
  if ($("#tosCheck").prop("checked") == true) {
    $("#loginSubmit").removeClass("no-active");
  } else {
    $("#loginSubmit").addClass("no-active");
  }

  $("#tosCheck").click(function () {
    if ($("#tosCheck").prop("checked") === true) {
      $("#loginSubmit").removeClass("no-active");
    } else {
      $("#loginSubmit").addClass("no-active");
    }
  });
  $("#tosLabel").click(function () {
    $("#tosCheck").click();
  });
  $("#tosLink").click(function () {
    open("/tos", "_blank");
    return false;
  });
  $('#loginSubmit').submit(function () {
    if ($("#tosCheck").prop("checked") !== true) {
      $(".tos-alert").show();
      return false;
    }
  });
  $('#loginSubmit').click(function () {
    if ($("#tosCheck").prop("checked") !== true) {
      $(".tos-alert").show();
      return false;
    }
  });
});